import Link from "next/link";
import classNames from "classnames";
import { RouterConfig } from "@shared/lib/routerConfig";
import { BreadcrumbsArrow } from "@shared/ui/Icon/ui/Common/BreadcrumbsArrow";
import { BreadcrumbsProps } from "./types";
import classes from "./Breadcrumbs.module.scss";

export const Breadcrumbs = ({
    className,
    prevItem,
    title = "Страница не найдена"
}: BreadcrumbsProps) => (
    <nav className={classNames(classes.breadcrumbs, className)} aria-label="breadcrumb">
        <ul className={classes.breadcrumbs__list}>
            <li>
                <Link href={RouterConfig.MAIN}>Главная</Link>
                <BreadcrumbsArrow className={classes.breadcrumbs__arrow} />
            </li>
            {prevItem && (
                <li>
                    <Link href={prevItem.href}>{prevItem.text}</Link>
                    <BreadcrumbsArrow className={classes.breadcrumbs__arrow} />
                </li>
            )}
            <li aria-current="page">{title}</li>
        </ul>
    </nav>
);