import React, { useEffect, useState } from "react";
import Link from "next/link";
import { RouterConfig } from "@shared/lib/routerConfig";
import { CookieConsentTexts } from "./constants";
import { getConsent, setConsent } from "./lib/useCookieConsent";
import styles from "./CookieConsent.module.scss";

export const CookieConsent = () => {
    const [isVisible, setIsVisible] = useState(false);

    const handleAccept = () => {
        setConsent(true);
        setIsVisible(false);
    };

    useEffect(() => {
        const consent = getConsent();
        if (!consent || !consent.isApproved) {
            setIsVisible(true);
        }
    }, []);

    if (!isVisible) {
        return null;
    }

    return (
        <div className={styles.cookie}>
            <div className={styles.cookie__message}>
                <span>{CookieConsentTexts.MESSAGE}</span>
                <Link className={styles.cookie__link} href={RouterConfig.PRIVACY_POLICY}>
                    {CookieConsentTexts.LINK}
                </Link>
            </div>
            <button className={styles.cookie__button} onClick={handleAccept}>
                {CookieConsentTexts.APPROVE}
            </button>
        </div>
    );
};