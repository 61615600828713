import { RouterConfig } from "@shared/lib/routerConfig";
import { TelegramFilledIcon } from "@shared/ui/Icon/ui/Socials/TelegramFilledIcon";
import { VkFilledIcon } from "@shared/ui/Icon/ui/Socials/VkFilledIcon";
import { YoutubeIcon } from "@shared/ui/Icon/ui/Socials/YoutubeIcon";

export const NAVIGATION = {
    LABEL: "Навигация:",
    ITEMS: [
        { TEXT: "Каталог", LINK: RouterConfig.CATALOG, ARIA_LABEL: "Перейти в каталог объявлений" },
        { TEXT: "Блог", LINK: RouterConfig.BLOG, ARIA_LABEL: "Перейти в блог" },
        {
            TEXT: "Страхование",
            LINK: RouterConfig.INSURANCE,
            ARIA_LABEL: "Перейти в раздел страхование"
        },
        {
            TEXT: "Договор купли-продажи",
            LINK: RouterConfig.GENERATOR_DKP,
            ARIA_LABEL: "Скачать купли-продажи"
        }
    ]
};

export const DOCUMENTS = {
    LABEL: "Документы:",
    ITEMS: [
        {
            TEXT: "Политика конфиденциальности",
            LINK: RouterConfig.PRIVACY_POLICY,
            ARIA_LABEL: "Ознакомиться с политикой конфиденциальности"
        },
        {
            TEXT: "Правила пользования сайтом",
            LINK: RouterConfig.TERMS_OF_USE,
            ARIA_LABEL: "Ознакомиться с правилами пользования сайтом"
        }
    ]
};

export const CONTACTS = {
    LABEL: "Контакты:",
    ITEMS: [
        {
            TEXT: "Поддержка",
            ADDRESS: "support@likvi.com",
            LINK: "mailto:support@likvi.com",
            ARIA_LABEL: "Электронная почта поддержки"
        },
        {
            TEXT: "Для партнеров",
            ADDRESS: "commerce@likvi.com",
            LINK: "mailto:commerce@likvi.com",
            ARIA_LABEL: "Электронная почта для партнеров"
        }
    ]
};

export const SOCIALS = [
    {
        name: "Вконтакте",
        href: "https://vk.com/likvicom",
        icon: VkFilledIcon,
        ariaLabel: "Перейти на страницу Likvi.com в Вконтакте"
    },
    {
        name: "YouTube",
        href: "https://youtube.com/@Likvicom?si=wpQC_sl2HLSNLWw_",
        icon: YoutubeIcon,
        ariaLabel: "Перейти на канал Likvi.com в YouTube"
    },
    {
        name: "Telegram",
        href: "https://t.me/likvicom",
        icon: TelegramFilledIcon,
        ariaLabel: "Перейти на канал Likvi.com в Telegram"
    }
];

export const COPYRIGHT_NOTICE = "(c) Likvi | Все права защищены";