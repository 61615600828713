import React, { useEffect, useState } from "react";
import Link from "next/link";
import classNames from "classnames";
import { useWindowSize } from "@src/hooks/useWindowsSize";
import { BannerImageProps } from "./types";
import classes from "./banner.module.scss";

export const BannerImage = ({ banners, card, isProductScreen, isHorizontal }: BannerImageProps) => {
    const size = useWindowSize();
    const [bannerImage, setBannerImage] = useState<string | undefined>(banners?.imageDesktop);

    useEffect(() => {
        if (size.width) {
            if (isProductScreen) {
                setBannerImage(banners?.imagePhone);
            } else if (isHorizontal) {
                setBannerImage(banners?.fullImage);
            } else {
                if (size.width > 1023) {
                    setBannerImage(banners?.imageDesktop);
                } else if (size.width > 767 && size.width <= 1023) {
                    setBannerImage(banners?.imageTablet);
                } else {
                    setBannerImage(banners?.imagePhone);
                }
            }
        }
    }, [size.width, isProductScreen, isHorizontal]);

    return isHorizontal ? (
        <div className={classes.wrapperHorizontal}>
            <Link href={banners?.url || ""} target="_blank" rel="nofollow noreferrer">
                <img src={bannerImage || ""} className={classes.img} alt="banner" />
            </Link>
        </div>
    ) : (
        <div className={classNames(card ? classes.wrapperCard : classes.wrapper)}>
            <Link href={banners?.url || ""} target="_blank" rel="nofollow noreferrer">
                <img src={bannerImage || ""} className={classes.img} alt="banner" />
            </Link>
        </div>
    );
};