import React from "react";
import Image from "next/image";
import classNames from "classnames";
import { TechnicalPauseProps } from "@features/TechnicalPause/TechnicalPauseProps";
import classes from "./TechnicalPause.module.scss";

const TechnicalPause = ({ minContentHeight }: TechnicalPauseProps) => {
    const cx = classNames.bind(classes);

    return (
        <div
            className={cx(classes.TechnicalPause)}
            style={{
                minHeight: minContentHeight
            }}
        >
            <div className={classes.Img}>
                <Image
                    priority
                    src="/svg/technical-pause.svg"
                    height={171}
                    width={220}
                    alt="На сайте ведутся технические работы"
                />
            </div>
            <div className={classes.Header}>На сайте ведутся технические работы</div>
            <div className={classes.P}>Обычно это занимает не более 1 часа</div>
        </div>
    );
};
export default TechnicalPause;