import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { QueryStatus } from "@reduxjs/toolkit/query";
import HearBtnIcon from "@components/Svg/HearBtnIcon";
import { selectIsAuthenticated } from "@app/store/authSlice";
import { AppDispatch } from "@app/store/store";
import { OfferIcon } from "@features/catalog";
import { CompareToggle } from "@features/compare/CompareToggle";
import { ProductPriceUsd } from "@features/product/product-price-usd";
import { useGetCompareOffersQuery } from "@entities/compare";
import { ProductSellerInfo } from "@entities/offers";
import {
    addToFavorits,
    getFavoritesList,
    removeFromFavorits
} from "@entities/offers/api/favorites-slice";
import Car from "@shared/lib/interfaces/car.interface";
import { getCurrencySymbol, toRelativeTime } from "@shared/lib/utils";
import { ViewsIcon } from "@shared/ui/Icon/ui/Common/ViewsIcon";
import ContactButton from "../../../components/UI/Buttons/ContactButton/ContactButton";
import { PrimaryButton } from "../../../components/UI/Buttons/PrimaryButton/PrimaryButton";
import LocationSnippet from "../../../components/UI/LocationSnippet/LocationSnippet";
import { numberWithSpaces } from "../../../utils/utils";
import { COUNTER_VIEWS_TITLE, TEXT_ADD_TO_FAVORITES, TEXT_BUTTON_URL_BOARDS } from "./constants";
import { ProductShare } from "./product-share/product-share";
import { ProductContactCardProps } from "./types";
import classes from "./product-contact-card.module.scss";

const ProductContactCard = ({ offer, sellerData }: ProductContactCardProps) => {
    const isLivkiOffer = !offer.sourceId;
    const couldBeOpenedOnSource = !isLivkiOffer && offer.sourceId !== 5;
    const priceInCurrency = `${numberWithSpaces(offer.price.toString())} ${getCurrencySymbol(offer.sourceId)}`;
    const dispatch: AppDispatch = useDispatch();

    const fetchFavoritesList = async () => {
        const resultAction = await dispatch(getFavoritesList());

        if (getFavoritesList.fulfilled.match(resultAction) && resultAction?.payload) {
            setIsFavorite(resultAction.payload.some((offerData) => offerData.id === offer.id));
        }
    };

    const isAuthorized = useSelector(selectIsAuthenticated);

    const { data: compareData } = useGetCompareOffersQuery(undefined, {
        skip: !isAuthorized
    });

    const [isFavorite, setIsFavorite] = useState(false);
    const [_isComparison, setIsComparison] = useState(false);
    const locationText = `${offer.city || ""}${offer.city && offer.address ? ", " : ""}${offer.address || ""}`;

    useEffect(() => {
        if (compareData) {
            setIsComparison(compareData.offers.some((item: Car) => item.id === offer.id));
        }
    }, [offer, compareData]);

    const handleFavoriteClick = async (e: React.MouseEvent) => {
        e.preventDefault();
        if (isFavorite) {
            const result = await dispatch(
                removeFromFavorits({
                    id: offer?.id || ""
                })
            );
            if (result.meta.requestStatus === QueryStatus.fulfilled) {
                setIsFavorite(false);
            }
        } else {
            setIsFavorite(!isFavorite);
            const result = await dispatch(
                addToFavorits({
                    id: offer?.id || ""
                })
            );
            if (result.meta.requestStatus !== QueryStatus.fulfilled) {
                setIsFavorite(false);
            }
        }
    };

    useEffect(() => {
        fetchFavoritesList();
    }, [offer]);

    return (
        <div className={classes.ProductContactCard}>
            <div className={classes.ProductContactCard__top}>
                <h1 className={classes["ProductContactCard__top-title"]}>{offer.title}</h1>
                <div className={classes["ProductContactCard__top-actions"]}>
                    <div className={classes.ProductContactCard__favorite}>
                        <a
                            className={classes["ProductContactCard__favorite-icon"]}
                            aria-label={TEXT_ADD_TO_FAVORITES}
                            onClick={handleFavoriteClick}
                            href=""
                        >
                            <HearBtnIcon isSelected={isFavorite} />
                        </a>
                    </div>
                    <CompareToggle
                        className={classes.CompareOffer}
                        classNameIcon={classes.CompareOffer__icon}
                        classNameIconActive={classes["CompareOffer__icon--active"]}
                        offerId={offer.id}
                        isAuth={isAuthorized}
                    />
                    <ProductShare />
                </div>
            </div>
            <div className={classes.Price}>
                <span className={classes.Price__source}>{priceInCurrency}</span>
                <ProductPriceUsd
                    className={classes.Price__usd}
                    price={offer.price}
                    priceUsd={offer.priceUsd}
                    sourceId={offer.sourceId}
                    createdAt={offer.createdAt}
                    updatedAt={offer.updatedAt}
                    isUpdated={true}
                    offerUuid={offer.id}
                />
            </div>

            <div className={classes.Tags}>
                <div className={classes.Tags__specs}>
                    <span>{offer.year} г</span>
                    {offer.mileage && <span>{offer.mileage} км</span>}
                </div>
                <div className={classes.Tags__metrics} title={COUNTER_VIEWS_TITLE}>
                    <ViewsIcon />
                    <span>{offer.totalViews}</span>
                </div>
            </div>

            <div className={classes.Divider} />
            <div className={classes.Content}>
                <div className={classes.Info}>
                    <div className={classes.Props}>
                        {offer.props.slice(0, 5).map((item, index) => {
                            return (
                                <div className={classes.Prop} key={index}>
                                    <div className={classes.PropLabel}>{item.label}</div>
                                    <div className={classes.PropValue}>{item.value}</div>
                                </div>
                            );
                        })}
                    </div>
                    <div className={classes.DividerDesktop} />
                    <div className={`${classes.Location} ${classes.DesktopOnly}`}>
                        <LocationSnippet text={locationText} />
                    </div>
                    {sellerData ? (
                        <ProductSellerInfo sellerData={sellerData} />
                    ) : (
                        <div className={classes.DividerDesktop} />
                    )}
                </div>

                <div className={classes.Buttons}>
                    {offer.phone && (
                        <div
                            style={{
                                marginBottom: "1em"
                            }}
                        >
                            <ContactButton
                                phone={offer.phone}
                                name={offer.contact}
                                isAuth={isAuthorized}
                            />
                        </div>
                    )}
                    {couldBeOpenedOnSource && (
                        <PrimaryButton
                            type="Large"
                            onClick={() => {
                                window.open(offer.url, "_blank");
                            }}
                            content={TEXT_BUTTON_URL_BOARDS}
                        />
                    )}
                </div>
            </div>
            <div className={classes.DividerMobile} />

            <div className={classes.Footer}>
                <OfferIcon className={classes.Footer_icon} sourceId={offer.sourceId} />
                <div className={classes.UpdatedTime}>{toRelativeTime(offer.createdAt)}</div>
            </div>
        </div>
    );
};
export default ProductContactCard;