import React, { useEffect, useRef, useState } from "react";
import dynamic from "next/dynamic";
import Image from "next/image";
import classNames from "classnames";
import { useKeenSlider } from "keen-slider/react";
import PinchZoom from "pinch-zoom-js";
import GalleryNextIcon from "@components/Svg/GalleryNextIcon";
import GalleryPrevIcon from "@components/Svg/GalleryPrevIcon";
import { generateCarImageAlt, generateCarImageTitle } from "@features/seo";
import PlaceholderPic from "@shared/ui/Icon/ui/PlaceholderPic";
import { PREV_QNT } from "./constnats";
import { GalleryProps } from "./types";
import classes from "./gallery.module.scss";

const CustomLightbox = dynamic(
    () => import("./custom-lightbox/custom-lightbox").then((mod) => mod.CustomLightbox),
    {
        ssr: false
    }
);

export const Gallery = ({ offer }: GalleryProps) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isOpenLightbox, setIsOpenLightbox] = useState(false);
    const zoomRef = useRef<HTMLDivElement | null>(null);

    const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
        loop: true,
        drag: false,
        initial: 0,
        slides: {
            perView: 1,
            spacing: 20
        },
        slideChanged(slider) {
            setCurrentImageIndex(slider.track.details.rel);
        }
    });

    const images = offer.pics.map((image) => {
        return {
            src: image.bigUrl ? image.bigUrl : "https://dummyimage.com/640x360/fff/aaa",
            alt: generateCarImageAlt(offer),
            title: generateCarImageTitle(offer)
        };
    });

    const totalImages = images.length;
    const showActions = totalImages > 1;
    const previews = images.slice(1, PREV_QNT + 1);

    const openFullscreen = () => setIsOpenLightbox(true);

    const handlePrevClick = () => {
        instanceRef.current?.prev();
    };

    const handleNextClick = () => {
        instanceRef.current?.next();
    };

    const handleThumbnailClick = (index: number) => instanceRef.current?.moveToIdx(index + 1);

    const syncSliderWithLightbox = (index: number) => {
        setCurrentImageIndex(index);
        instanceRef.current?.moveToIdx(index);
    };

    useEffect(() => {
        if (!zoomRef.current || zoomRef.current.offsetHeight === 0) return;
        /* eslint-disable no-new */
        new PinchZoom(zoomRef.current, {
            draggableUnzoomed: false,
            minZoom: 1
        });
    }, [currentImageIndex]);

    return (
        <div className={classes.gallery}>
            {images.length === 0 ? (
                <div className={classes.gallery__placeholder}>
                    <PlaceholderPic />
                </div>
            ) : (
                <div ref={sliderRef} className={classNames("keen-slider", classes.gallery__inner)}>
                    {images.map((img, index) => {
                        return (
                            <div
                                key={index}
                                className={classNames("keen-slider__slide", classes.gallery__slide)}
                                onClick={openFullscreen}
                            >
                                <div
                                    className={classes.gallery__wrapper}
                                    ref={index === currentImageIndex ? zoomRef : null}
                                >
                                    <Image
                                        className={classes.gallery__image}
                                        src={img.src}
                                        alt={img.alt}
                                        fill
                                        sizes="(max-width: 775px) 100vw, 775px"
                                        quality={70}
                                        loading={index === 0 ? "eager" : "lazy"}
                                    />
                                </div>
                            </div>
                        );
                    })}
                    {showActions && (
                        <>
                            <div
                                className={classNames(
                                    classes.gallery__arrow,
                                    classes["gallery__arrow--left"]
                                )}
                                onClick={handlePrevClick}
                            >
                                <GalleryPrevIcon />
                            </div>
                            <div
                                className={classNames(
                                    classes.gallery__arrow,
                                    classes["gallery__arrow--right"]
                                )}
                                onClick={handleNextClick}
                            >
                                <GalleryNextIcon />
                            </div>
                        </>
                    )}
                </div>
            )}
            {showActions && (
                <div className={classes.thumbnails}>
                    {previews.map((image, index) => {
                        return (
                            <div
                                onClick={() => handleThumbnailClick(index)}
                                key={index}
                                className={classes.thumbnails__item}
                            >
                                <Image
                                    className={classes.thumbnails__image}
                                    src={image.src}
                                    width={140}
                                    height={100}
                                    quality={70}
                                    alt={image.alt}
                                    title={image.title}
                                />
                            </div>
                        );
                    })}
                </div>
            )}
            {showActions && (
                <CustomLightbox
                    open={isOpenLightbox}
                    close={() => setIsOpenLightbox(false)}
                    index={currentImageIndex}
                    slides={images}
                    onViewChange={syncSliderWithLightbox}
                />
            )}
        </div>
    );
};