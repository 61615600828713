import React, { FC } from "react";
import { IconProps } from "@shared/ui/Icon/ui/types";

export const MigTorgBlackIcon: FC<IconProps> = ({ className }) => (
    <svg className={className} viewBox="0 0 170 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M57 40V0H158L170 20L158 40H57Z" fill="#D6514A" />
        <path
            d="M18.76 10.3999V29.9999H15.54V16.0279L9.576 25.8839H9.184L3.22 15.9999V29.9999H0V10.3999H3.472L9.38 20.1999L15.316 10.3999H18.76Z"
            fill="black"
        />
        <path d="M22.668 10.3999H25.888V29.9999H22.668V10.3999Z" fill="black" />
        <path
            d="M48.8727 19.8639V21.2079C48.8727 23.8959 47.986 26.0986 46.2127 27.8159C44.4394 29.5146 42.1247 30.3639 39.2687 30.3639C36.2447 30.3639 33.7434 29.3839 31.7647 27.4239C29.8047 25.4639 28.8247 23.0559 28.8247 20.1999C28.8247 17.3626 29.8047 14.9639 31.7647 13.0039C33.7434 11.0252 36.1887 10.0359 39.1007 10.0359C40.93 10.0359 42.6007 10.4559 44.1127 11.2959C45.6247 12.1359 46.8007 13.2652 47.6407 14.6839L44.8687 16.2799C44.346 15.3466 43.5714 14.5999 42.5447 14.0399C41.518 13.4612 40.37 13.1719 39.1007 13.1719C37.0474 13.1719 35.358 13.8439 34.0327 15.1879C32.7074 16.5319 32.0447 18.2119 32.0447 20.2279C32.0447 22.2439 32.7074 23.9146 34.0327 25.2399C35.3767 26.5652 37.1314 27.2279 39.2967 27.2279C41.0327 27.2279 42.442 26.8266 43.5247 26.0239C44.626 25.2026 45.326 24.1106 45.6247 22.7479H39.0727V19.8639H48.8727Z"
            fill="black"
        />
        <path
            d="M79.74 10.3999V13.4799H74.224V29.9999H71.004V13.4799H65.46V10.3999H79.74Z"
            fill="white"
        />
        <path
            d="M97.38 27.4239C95.42 29.3839 93.0213 30.3639 90.184 30.3639C87.3466 30.3639 84.9386 29.3839 82.96 27.4239C81 25.4452 80.02 23.0372 80.02 20.1999C80.02 17.3626 81 14.9639 82.96 13.0039C84.9386 11.0252 87.3466 10.0359 90.184 10.0359C93.0213 10.0359 95.42 11.0252 97.38 13.0039C99.3586 14.9639 100.348 17.3626 100.348 20.1999C100.348 23.0372 99.3586 25.4452 97.38 27.4239ZM85.228 25.2399C86.572 26.5652 88.224 27.2279 90.184 27.2279C92.144 27.2279 93.7866 26.5652 95.112 25.2399C96.456 23.8959 97.128 22.2159 97.128 20.1999C97.128 18.1839 96.456 16.5132 95.112 15.1879C93.7866 13.8439 92.144 13.1719 90.184 13.1719C88.224 13.1719 86.572 13.8439 85.228 15.1879C83.9026 16.5132 83.24 18.1839 83.24 20.1999C83.24 22.2159 83.9026 23.8959 85.228 25.2399Z"
            fill="white"
        />
        <path
            d="M114.457 29.9999L110.313 22.8599H106.505V29.9999H103.285V10.3999H111.125C112.88 10.3999 114.364 11.0159 115.577 12.2479C116.809 13.4612 117.425 14.9452 117.425 16.6999C117.425 17.9506 117.061 19.0986 116.333 20.1439C115.624 21.1706 114.69 21.9172 113.533 22.3839L117.985 29.9999H114.457ZM106.505 13.4239V19.9759H111.125C111.984 19.9759 112.712 19.6586 113.309 19.0239C113.906 18.3892 114.205 17.6146 114.205 16.6999C114.205 15.7852 113.906 15.0106 113.309 14.3759C112.712 13.7412 111.984 13.4239 111.125 13.4239H106.505Z"
            fill="white"
        />
        <path
            d="M139.388 19.8639V21.2079C139.388 23.8959 138.502 26.0986 136.728 27.8159C134.955 29.5146 132.64 30.3639 129.784 30.3639C126.76 30.3639 124.259 29.3839 122.28 27.4239C120.32 25.4639 119.34 23.0559 119.34 20.1999C119.34 17.3626 120.32 14.9639 122.28 13.0039C124.259 11.0252 126.704 10.0359 129.616 10.0359C131.446 10.0359 133.116 10.4559 134.628 11.2959C136.14 12.1359 137.316 13.2652 138.156 14.6839L135.384 16.2799C134.862 15.3466 134.087 14.5999 133.06 14.0399C132.034 13.4612 130.886 13.1719 129.616 13.1719C127.563 13.1719 125.874 13.8439 124.548 15.1879C123.223 16.5319 122.56 18.2119 122.56 20.2279C122.56 22.2439 123.223 23.9146 124.548 25.2399C125.892 26.5652 127.647 27.2279 129.812 27.2279C131.548 27.2279 132.958 26.8266 134.04 26.0239C135.142 25.2026 135.842 24.1106 136.14 22.7479H129.588V19.8639H139.388Z"
            fill="white"
        />
        <path
            d="M160 20C160 22.2091 158.209 24 156 24C153.791 24 152 22.2091 152 20C152 17.7909 153.791 16 156 16C158.209 16 160 17.7909 160 20Z"
            fill="white"
        />
    </svg>
);