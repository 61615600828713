import Link from "next/link";
import { CURRENT_YEAR } from "@shared/config/Date";
import { SLOGAN_ADDITIONAL_TEXT, SLOGAN_TEXT } from "@shared/config/GlobalText";
import Logo from "../../components/UI/Logo/Logo";
import { CONTACTS, COPYRIGHT_NOTICE, DOCUMENTS, NAVIGATION, SOCIALS } from "./constants";
import classes from "./Footer.module.scss";

const Footer = () => {
    return (
        <footer className={classes.footer}>
            <div className={classes.footer__wrapper}>
                <div className={classes.footer__inner}>
                    <div className={classes.footer__logo}>
                        <Logo width={230} height={36} />
                        <div className={classes.footer__slogan}>
                            {SLOGAN_TEXT} {SLOGAN_ADDITIONAL_TEXT}
                        </div>
                    </div>

                    <div className={classes.footer__column}>
                        <label className={classes.footer__label}>{NAVIGATION.LABEL}</label>
                        <ul className={classes.footer__list}>
                            {NAVIGATION.ITEMS.map((item, index) => (
                                <li key={index}>
                                    <Link href={item.LINK} aria-label={item.ARIA_LABEL}>
                                        {item.TEXT}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className={classes.footer__column}>
                        <label className={classes.footer__label}>{DOCUMENTS.LABEL}</label>
                        <ul className={classes.footer__list}>
                            {DOCUMENTS.ITEMS.map((item, index) => (
                                <li key={index}>
                                    <Link
                                        href={item.LINK}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        aria-label={item.ARIA_LABEL}
                                    >
                                        {item.TEXT}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className={classes.footer__column}>
                        <label className={classes.footer__label}>{CONTACTS.LABEL}</label>
                        <ul className={classes.footer__list}>
                            {CONTACTS.ITEMS.map((item, index) => (
                                <li key={index}>
                                    {item.TEXT} -{" "}
                                    <a href={item.LINK} aria-label={item.ARIA_LABEL}>
                                        {item.ADDRESS}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className={classes.footer__bottom}>
                    <span className={classes.footer__copyright}>
                        {CURRENT_YEAR} {COPYRIGHT_NOTICE}
                    </span>
                    <div className={classes.footer__socials}>
                        {SOCIALS.map((social, index) => (
                            <Link
                                key={index}
                                href={social.href}
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label={social.ariaLabel}
                                className={classes["footer__socials--link"]}
                            >
                                <social.icon className={classes["footer__icon"]} />
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;