import React from "react";
import CardsList from "../catalog/cards-list/cards-list";
import { SimilarProductsListProps } from "./types";
import classes from "./SimilarProductsList.module.scss";

const SimilarProductsList = ({ data, isLoading, banners }: SimilarProductsListProps) => {
    return (
        <div className={classes.SimilarProductsList}>
            <h3>Похожие объявления</h3>
            {isLoading && <div className="custom-loader"></div>}
            {!isLoading && data && <CardsList productsData={data} banners={banners} />}
        </div>
    );
};
export default SimilarProductsList;