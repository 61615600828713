import React from "react";
import { useDispatch } from "react-redux";
import dynamic from "next/dynamic";
import { formatToPhone } from "@src/utils/utils";
import { showEnterForm } from "@app/store/layoutSlice";
import { PrimaryButton } from "../PrimaryButton/PrimaryButton";
import { AUTH_FORM_TEXT } from "./constants";
import { ContactButtonProps } from "./types";

const MotionDiv = dynamic(() => import("framer-motion").then((mod) => mod.motion.div), {
    ssr: false
});

const ContactButton = ({ phone, name, isAuth = false }: ContactButtonProps) => {
    const dispatch = useDispatch();
    const formatedPhone = phone ? formatToPhone(phone) : "Номер неизвестен";
    const maskedPhone = phone ? formatedPhone.slice(0, -7) + "X-XX-XX" : "Номер неизвестен";
    const formattedName = name ?? "";
    const phoneRef = React.useRef<HTMLAnchorElement>(null);
    const [isPhoneLoading, setIsPhoneLoading] = React.useState(false);
    const [isPhoneVisible, setIsPhoneVisible] = React.useState(false);

    const phoneButtonVariants = {
        hidden: {
            display: "none",
            opacity: 0,
            scale: 0
        },
        visible: {
            display: "block",
            opacity: 1,
            scale: 1,
            transition: {
                duration: 0.2
            }
        }
    };

    const handleShowPhoneClick = () => {
        if (!isAuth) {
            dispatch(showEnterForm(AUTH_FORM_TEXT));
            return;
        }

        if (isPhoneVisible) {
            if (phoneRef.current) {
                phoneRef.current?.click();
            }
            return;
        }

        setIsPhoneLoading(true);
        setTimeout(() => {
            setIsPhoneVisible(!isPhoneVisible);
            setIsPhoneLoading(false);
        }, 1000);
    };

    return (
        <>
            <PrimaryButton
                type="Primary"
                isLoading={isPhoneLoading}
                onClick={handleShowPhoneClick}
                content={
                    <div style={{ height: 32, display: "flex", alignItems: "center" }}>
                        <MotionDiv
                            initial="hidden"
                            variants={phoneButtonVariants}
                            animate={isPhoneVisible ? "visible" : "hidden"}
                            transition={{
                                duration: 0.2
                            }}
                        >
                            <div>
                                <a ref={phoneRef} href={"tel:" + formatedPhone}>
                                    {formatedPhone}
                                </a>
                            </div>
                            {formattedName && <div>{formattedName}</div>}
                        </MotionDiv>
                        <MotionDiv
                            variants={phoneButtonVariants}
                            animate={isPhoneVisible ? "hidden" : "visible"}
                            transition={{
                                duration: 0.2
                            }}
                        >
                            <div>{maskedPhone}</div>
                            <div>Показать телефон</div>
                        </MotionDiv>
                    </div>
                }
            />
        </>
    );
};
export default ContactButton;