import React, { FC } from "react";
import { IconProps } from "@shared/ui/Icon/ui/types";

export const CompanyIcon: FC<IconProps> = ({ className }) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="21"
        fill="none"
    >
        <path
            fill="#1D1D1B"
            d="M8.423 7.233H5.79a.786.786 0 0 1-.783-.783c0-.43.353-.783.783-.783h2.633c.43 0 .784.353.784.783 0 .43-.353.783-.784.783ZM8.423 10.933c.43 0 .784.353.784.784 0 .43-.353.783-.784.783H5.79a.786.786 0 0 1-.783-.783c0-.43.353-.784.783-.784h2.633Z"
        />
        <path
            fill="#1D1D1B"
            fillRule="evenodd"
            d="M13.815 2.83c0 .112.091.203.204.203h4.28c1.157 0 2.1.942 2.1 2.1v13.825c0 .796-.646 1.442-1.442 1.442H1.84a1.442 1.442 0 0 1-1.442-1.442V2.5c0-1.158.943-2.1 2.1-2.1h9.217c1.158 0 2.1.942 2.1 2.1v.33Zm.204 1.77a.204.204 0 0 0-.204.204v3.292c0 .112.091.204.204.204h4.609a.204.204 0 0 0 .204-.204V5.133a.534.534 0 0 0-.534-.533H14.02Zm4.813 5.47a.204.204 0 0 0-.204-.203h-4.609a.204.204 0 0 0-.204.204v3.291c0 .113.091.204.204.204h4.609a.204.204 0 0 0 .204-.204v-3.291Zm0 5.267a.204.204 0 0 0-.204-.204h-4.609a.204.204 0 0 0-.204.204v3.292c0 .113.091.204.204.204h4.609a.204.204 0 0 0 .204-.204v-3.292Zm-6.788 3.496a.204.204 0 0 0 .204-.204V2.5a.534.534 0 0 0-.533-.533H2.5a.534.534 0 0 0-.534.533v16.129c0 .113.092.204.204.204h3.95a.204.204 0 0 0 .204-.204v-1.646c0-.482.438-.864.942-.767.361.071.625.416.625.807v1.606c0 .113.092.204.204.204h3.95Z"
            clipRule="evenodd"
        />
    </svg>
);