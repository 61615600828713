import { useCallback, useState } from "react";
import { UseCopyToClipboardReturn } from "./types";

export const useCopyToClipboard = (text: string): UseCopyToClipboardReturn => {
    const [copied, setCopied] = useState<boolean>(false);

    const copy = useCallback(() => {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                setCopied(true);
                setTimeout(() => setCopied(false), 2000);
            })
            .catch((err) => {
                console.error("Ошибка при копировании в буфер обмена:", err);
                setCopied(false);
            });
    }, [text]);

    return [copied, copy];
};