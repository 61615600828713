import Image from "next/image";
import { VerifiedIcon } from "@shared/ui/Icon/ui/Profile/VerifiedIcon";
import { WaitingIcon } from "@shared/ui/Icon/ui/Profile/WaitingIcon";
import { ProductSellerInfoProps } from "./types";
import classes from "./product-seller-info.module.scss";

export const ProductSellerInfo = ({ sellerData }: ProductSellerInfoProps) => {
    if (!sellerData) return null;

    const isCompany = "name" in sellerData;

    const seller = {
        avatar: isCompany && sellerData.logoUrl ? sellerData.logoUrl : null,
        defaultAvatar: isCompany
            ? "/img/profile/seller-company.webp"
            : "/img/profile/seller-public.webp",
        name: isCompany ? sellerData.name : sellerData.firstName || "Частное лицо",
        type: isCompany ? "Компания" : "Частное лицо",
        alt: isCompany ? `Логотип компании - ${sellerData.name}` : "Логотип продавца"
    };

    return (
        <div className={classes.seller}>
            <div className={classes.seller__inner}>
                <Image
                    src={seller.avatar || seller.defaultAvatar}
                    alt={seller.alt}
                    width={46}
                    height={46}
                    className={classes.seller__avatar}
                    unoptimized={!seller.avatar}
                    onError={(e) => {
                        e.currentTarget.src = seller.defaultAvatar;
                        e.currentTarget.srcset = seller.defaultAvatar;
                    }}
                />
                <div className={classes.seller__info}>
                    <span className={classes.seller__name}>{seller.name}</span>
                    <span className={classes.seller__type}>{seller.type}</span>
                </div>
            </div>
            {isCompany && (
                <div className={classes.seller__status}>
                    {sellerData.isVerified ? (
                        <>
                            <VerifiedIcon />
                            Проверенный аккаунт
                        </>
                    ) : (
                        <>
                            <WaitingIcon />
                            Аккаунт на модерации
                        </>
                    )}
                </div>
            )}
        </div>
    );
};