import dynamic from "next/dynamic";
import classNames from "classnames";
import ArrowRightIcon from "@components/Svg/ArrowRightIcon";
import { PrimaryButtonProps } from "./types";
import classes from "./PrimaryButton.module.scss";

const MotionButton = dynamic(() => import("framer-motion").then((mod) => mod.motion.button), {
    ssr: false
});

export const PrimaryButton = ({
    content,
    style,
    icon,
    type,
    onClick,
    className,
    isLoading,
    isDisabled,
    hasArrow,
    height = 56,
    leftOriented
}: PrimaryButtonProps) => {
    const arrowColor = type === "Primary" || !type ? "#fff" : "#7B869B";

    return (
        <MotionButton
            disabled={isDisabled}
            onClick={onClick}
            style={{ ...style, height }}
            className={classNames(
                classes.PrimaryButton,
                className,
                type && classes[type],
                isLoading && classes.Loading,
                leftOriented && classes.LeftOriented
            )}
        >
            {isLoading && <div className={classes.Loader} />}

            <div className={classes.Content}>
                {icon && <div className={classes.Icon}>{icon}</div>}
                {content}
            </div>
            {hasArrow && (
                <div className={classes.Arrow}>
                    <ArrowRightIcon color={arrowColor} />
                </div>
            )}
        </MotionButton>
    );
};