import React, { createElement, useRef, useState } from "react";
import useOnClickOutside from "@src/hooks/userOutsideHook";
import { RouterConfig } from "@shared/lib/routerConfig";
import { AutoIcon } from "@shared/ui/Icon/ui/Navigation/AutoIcon";
import { DocumentsIcon } from "@shared/ui/Icon/ui/Navigation/DocumentsIcon";
import { EstimateIcon } from "@shared/ui/Icon/ui/Navigation/EstimateIcon";
import { HomeIcon } from "@shared/ui/Icon/ui/Navigation/HomeIcon";
import { InsuranceIcon } from "@shared/ui/Icon/ui/Navigation/InsuranceIcon";
import MenuButton from "../../Header/MenuButton/MenuButton";
import { MobileMenuProps } from "./types";
import classes from "./MobileMenu.module.scss";

const MobileMenu = ({ activeItem, className }: MobileMenuProps) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    /* TODO Скрываем Dropdown меню и секцию будущих фич до подключения Маркетплейсы, Работа
           https://jira.gfun.club/browse/LIKVI-1346
    const dispatch = useDispatch();
    */

    useOnClickOutside(ref, (event: any) => {
        const isTargetButton = event?.path?.some((el: HTMLElement) => {
            if (el.getAttribute) {
                const id = el.getAttribute("id");
                return id === "mobile-menu";
            } else {
                return false;
            }
        });

        const isTargetButton2 = event?.composedPath()?.some((el: HTMLElement) => {
            if (el.getAttribute) {
                const id = el.getAttribute("id");
                return id === "mobile-menu";
            } else {
                return false;
            }
        });

        if (!isTargetButton && !isTargetButton2) {
            setIsExpanded(false);
        }
    });

    const Icon = activeItem.icon;
    return (
        <div id="mobile-menu" className={`${classes.MobileMenu} ${className}`}>
            <span
                onClick={() => {
                    setIsExpanded(!isExpanded);
                }}
            >
                <div className={classes.ButtonContainer}>
                    <Icon color="#3B85F7" />
                    <span>{activeItem.title}</span>
                </div>
                <div className={classes.Icon}>
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M6.90944 9.77806C6.59702 10.0905 6.59702 10.597 6.90944 10.9094L11.4349 15.4349C11.7473 15.7473 12.2539 15.7473 12.5663 15.4349L17.0918 10.9094C17.4042 10.597 17.4042 10.0905 17.0918 9.77806C16.7794 9.46565 16.2728 9.46565 15.9604 9.77806L12.0006 13.7379L8.04081 9.77806C7.72839 9.46565 7.22185 9.46565 6.90944 9.77806Z"
                            fill="#3B85F7"
                        />
                    </svg>
                </div>
            </span>

            {isExpanded && (
                <div ref={ref} className={classes.ExpandedMenuCont}>
                    <div className={classes.ExpandedMenu}>
                        <div className={classes.Li}>
                            <MenuButton
                                title={"Главная"}
                                icon={createElement(HomeIcon)}
                                link={RouterConfig.MAIN}
                            />
                        </div>
                        <div className={classes.Li}>
                            <MenuButton
                                title={"Авто"}
                                icon={createElement(AutoIcon)}
                                link={RouterConfig.CATALOG}
                            />
                        </div>
                        <div className={classes.Li}>
                            <MenuButton
                                title={"Страхование"}
                                icon={createElement(InsuranceIcon)}
                                link={RouterConfig.INSURANCE}
                            />
                        </div>
                        <div className={classes.Li}>
                            <MenuButton
                                title={"Оценка авто"}
                                icon={createElement(EstimateIcon)}
                                link={RouterConfig.ANALYTICS}
                            />
                        </div>
                        <div className={classes.Li}>
                            <MenuButton
                                title={"Документы"}
                                icon={createElement(DocumentsIcon)}
                                link={RouterConfig.GENERATOR_DKP}
                            />
                        </div>
                        {/* TODO Скрываем Dropdown меню и секцию будущих фич до подключения Маркетплейсы, Работа
                        https://jira.gfun.club/browse/LIKVI-1346
                        <div
                            className={classes.Disabled}
                            onClick={(e) => {
                                e.preventDefault();
                                dispatch(setIsSoonVisible(true));
                            }}
                        >
                            <div className={classes.Soon}>Скоро</div>
                            <div className={classes.Li}>
                                <MenuButton
                                    disabled={true}
                                    title={"Маркетплейсы"}
                                    icon={createElement(MarketsIcon)}
                                    link={RouterConfig.MARKETPLACES}
                                />
                            </div>
                            <div className={classes.Li}>
                                <MenuButton
                                    disabled={true}
                                    title={"Работа"}
                                    icon={createElement(JobIcon)}
                                    link={RouterConfig.JOB}
                                />
                            </div>
                            <div className={classes.Li}>
                                <MenuButton
                                    disabled={true}
                                    title={"Недвижимость"}
                                    icon={createElement(PropertyIcon)}
                                    link={RouterConfig.PROPERTY}
                                />
                            </div>
                        </div>
                        */}
                    </div>
                </div>
            )}
        </div>
    );
};
export default MobileMenu;