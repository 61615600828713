import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import Link from "next/link";
import { useRouter } from "next/router";
import useOnClickOutside from "@src/hooks/userOutsideHook";
import { AppDispatch } from "@app/store/store";
import { isPrivateRoute } from "@features/auth/AuthCheck";
import { logoutUserSessions } from "@entities/auth";
import { RouterConfig } from "@shared/lib/routerConfig";
import { UserMenuProps } from "./types";
import classes from "./UserMenu.module.scss";

const UserMenu = ({ isCompany = false, close }: UserMenuProps) => {
    const router = useRouter();
    const ref = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch<AppDispatch>();

    const profilePath = isCompany ? RouterConfig.PROFILE_COMPANY : RouterConfig.PROFILE;

    useOnClickOutside(ref, (event: any) => {
        const isTargetButton = (event?.path || []).some((el: HTMLElement) => {
            if (el.getAttribute) {
                const id = el.getAttribute("id");
                return id === "user-menu-button";
            } else {
                return false;
            }
        });
        if (!isTargetButton) {
            close();
        }
    });

    const handleLogout = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        if (isPrivateRoute(router.pathname)) {
            await router.push(RouterConfig.MAIN);
        }
        await dispatch(logoutUserSessions()).unwrap();
    };

    return (
        <div ref={ref} className={classes.UserMenu}>
            <ul className={classes.UserMenu__list}>
                <li className={classes["UserMenu__list-item"]}>
                    <Link className={classes.UserMenu__link} href={profilePath}>
                        Профиль
                    </Link>
                </li>
                <li className={classes["UserMenu__list-item"]}>
                    <Link className={classes.UserMenu__link} href={RouterConfig.MY_ADS}>
                        Мои объявления
                    </Link>
                </li>
                <li className={classes["UserMenu__list-item"]}>
                    <Link className={classes.UserMenu__link} href={RouterConfig.FAVORITES}>
                        Избранное
                    </Link>
                </li>
                <li className={classes["UserMenu__list-item"]}>
                    <a
                        className={classes.UserMenu__link}
                        href={RouterConfig.MAIN}
                        onClick={handleLogout}
                    >
                        Выйти
                    </a>
                </li>
            </ul>
        </div>
    );
};
export default UserMenu;