import React from "react";
import VerticalCatalogCard from "../vertical-catalog-card/vertical-catalog-card";
import { CardsListProps } from "./types";
import classes from "./cards-list.module.scss";

const CardsList = ({ productsData, banners }: CardsListProps) => {
    return (
        <div className={classes.CardsList}>
            <ul className={`${classes.CardList} ${classes.Vertical}`}>
                {productsData.map((item, index) => {
                    return (
                        <li key={index}>
                            <VerticalCatalogCard data={item} banners={banners} />
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};
export default CardsList;