import CarDetails from "@src/interfaces/carDetails.interface";
import { createLabel } from "@src/utils/utils";
import Car from "@shared/lib/interfaces/car.interface";
import { getCurrencySymbol } from "@shared/lib/utils";
import { Countries } from "./seo.constants";

interface GenerateCatalogTitleParams {
    brand?: string;
    model?: string;
    generation?: string;
    city?: string;
    qnt?: number;
}

export const generateCatalogTitle = (params: GenerateCatalogTitleParams) => {
    let title = `Продажа`;

    if (params.brand) {
        title += ` ${params.brand}`;
    }

    if (params.model) {
        title += ` ${params.model}`;
    }

    if (params.generation) {
        title += ` ${params.generation}`;
    }

    if (!params.brand && !params.model && !params.generation) {
        title += ` автомобилей`;
    }

    if (params.city) {
        title += ` в городе ${params.city}`;
    } else {
        title += ` в вашем городе`;
    }

    return title;
};

export interface GenerateCatalogHeader1Params extends GenerateCatalogTitleParams {
    qnt: number;
    country?: Countries;
}

const getCountryString = (country?: Countries) => {
    switch (country) {
        case Countries.KZ:
            return ` в Казахстане`;
        case Countries.RU:
            return ` в России`;
        case Countries.BY:
            return ` в Беларуси`;
        case Countries.DE:
            return ` в Германии`;
        default:
            return ``;
    }
};

export const generateCatalogHeader1 = (params: GenerateCatalogHeader1Params) => {
    let head1 = ``;
    let countryString = getCountryString(params.country);

    if (params.qnt === 0) {
        head1 += `Продажа`;

        if (params.brand) {
            head1 += ` ${params.brand}`;
        }

        if (params.model) {
            head1 += ` ${params.model}`;
        }

        if (params.generation) {
            head1 += ` ${params.generation}`;
        }

        if (!params.brand && !params.model && !params.generation) {
            head1 += ` автомобилей`;
        }

        if (params.city) {
            head1 += ` в городе ${params.city}`;
        } else {
            head1 += ` в вашем городе`;
            head1 += countryString;
        }
    } else {
        head1 += `${params.qnt} ${createLabel(params.qnt, [
            "объявление",
            "объявления",
            "объявлений"
        ])} о продаже`;

        if (params.brand) {
            head1 += ` ${params.brand}`;
        }

        if (params.model) {
            head1 += ` ${params.model}`;
        }

        if (params.generation) {
            head1 += ` ${params.generation}`;
        }

        if (params.city) {
            head1 += ` в городе ${params.city}`;
        } else {
            head1 += countryString;
        }
    }

    return head1;
};

interface GenerateCatalogDescriptionParams extends GenerateCatalogHeader1Params {}

export const generateCatalogDescription = (params: GenerateCatalogDescriptionParams) => {
    let countryString = "во всем мире";
    let description = ``;
    const modelId = `${params.brand ? params.brand + " " : ""}${params.model ? params.model + " " : ""}${
        params.generation ? params.generation : ""
    }`;

    if (params.qnt > 0) {
        description += `Более ${params.qnt}  ${createLabel(params.qnt, [
            "объявление",
            "объявления",
            "объявлений"
        ])} о продаже подержанных ${modelId} на автобазаре ${countryString}. На likvi.com легко найти, сравнить и купить Б/У ${modelId} с пробегом любого года.`;
    } else {
        description += `Продажа подержанных ${modelId} на автобазаре ${countryString}. На likvi.com легко найти, сравнить и купить Б/У ${modelId}} с пробегом любого года.`;
    }

    return description;
};

export const generateCarImageAlt = (offer: CarDetails) => {
    const engineVolume =
        offer.props.find((prop) => prop.label === "Объем двигателя")?.value.replace(" л.", "") ||
        "";
    const partBrandModelYear = `${offer.brand || ""} ${offer.model || ""} ${offer.year || ""}`;
    const partModelYear = `${offer.model || ""} ${offer.year || ""}`;
    const partPropsCar = `${offer.engine || ""} ${engineVolume} ${offer.body || ""}`.trim();
    const partLocation = `Б/У ${offer.city || ""}`;
    const altText = `${partBrandModelYear}, ${partModelYear} ${partPropsCar} ${partLocation}`;
    return altText.replace(/\s{2,}/g, " ").trim();
};

export const generateCarImageTitle = (offer: CarDetails) => {
    const brandModel = `${offer.brand || ""} ${offer.model || ""}`.trim();
    return `Продам ${brandModel} ${offer.year || ""} на likvi.com`;
};

export const generateCatalogImageAlt = (offer: Car) => {
    if (!offer.mark || !offer.model) {
        return `${offer.title}`;
    }
    const partBrandModelYear = `${offer.mark || ""} ${offer.model || ""} ${offer.year || ""}`;
    const partModelYear = `${offer.model || ""} ${offer.year || ""}`;
    const partPropsCar =
        `${offer.prepEngineType || ""} ${offer.displacement} ${offer.prepBodyType || ""}`.trim();
    const partLocation = `Б/У ${offer.city || ""}`;
    const altText = `${partBrandModelYear}, ${partModelYear} ${partPropsCar} ${partLocation}`;
    return altText.replace(/\s{2,}/g, " ").trim();
};

export const generateCatalogImageTitle = (offer: Car) => {
    if (!offer.mark || !offer.model) {
        return `Продам ${offer.title} на likvi.com`;
    }
    const markModel = `${offer.mark || ""} ${offer.model || ""}`.trim();
    return `Продам ${markModel} ${offer.year || ""} на likvi.com`;
};

export const generateProductTitle = (offer?: CarDetails) => {
    if (!offer) {
        return "";
    }
    const engineVolume =
        offer.props.find((prop) => prop.label === "Объем двигателя")?.value.replace(" л.", "") ||
        "";
    const partBrandModelYear = `${offer.brand || ""} ${offer.model || ""} ${offer.year || ""}`;
    const partModelYear = `${offer.model || ""} ${offer.year || ""}`;
    const partPropsCar = `${offer.engine || ""} ${engineVolume} ${offer.body || ""}`.trim();
    const partLocation = `Б/У ${offer.city || ""}`;
    const titleText = `Продам ${partBrandModelYear}, ${partModelYear} ${partPropsCar} ${partLocation}`;

    return titleText.replace(/\s{2,}/g, " ").trim();
};

export const generateProductDescription = (offer?: CarDetails) => {
    if (!offer) {
        return "";
    }
    const engineVolume =
        offer.props.find((prop) => prop.label === "Объем двигателя")?.value.replace(" л.", "") ||
        "";
    const partBrandModelYear = `${offer.brand || ""} ${offer.model || ""} ${offer.year || ""}`;
    const partModelYear = `${offer.model || ""} ${offer.year || ""}`;
    const partPropsCar = `${offer.engine || ""} ${engineVolume} ${offer.body || ""}`.trim();
    const partLocation = `Б/У ${offer.city || ""}`;
    const color = offer.color ? `, цвет ${offer.color}` : "";
    const mileage = offer.mileage ? `, пробег ${offer.mileage} км` : "";
    const lotName = `Код объявления: ${offer.id}.`;
    const currencySymbol = getCurrencySymbol(offer?.sourceId || 0);
    const pricePart = offer.price ? `Цена: ${offer.price} ${currencySymbol}` : "";

    const titleText = `Продаю на likvi.com ${partBrandModelYear}, ${partModelYear} ${partPropsCar} ${partLocation}${color}${mileage}. ${lotName} ${pricePart}`;
    return titleText.replace(/\s{2,}/g, " ").trim();
};