import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import classNames from "classnames";
import {
    removeLocalOffer,
    selectLocalOfferIds,
    useAddOfferToComparisonMutation,
    useGetCompareOffersQuery,
    useRemoveOfferFromComparisonMutation
} from "@entities/compare";
import { addLocalOffer } from "@entities/compare/model/compareSlice";
import { CompareIcon } from "@shared/ui/Icon/ui/Common/CompareIcon";
import { COMPARE_LIMIT_ERROR } from "./constants";
import { CompareToggleProps } from "./types";
import classes from "./CompareToggle.module.scss";

export const CompareToggle = ({
    offerId,
    isAuth,
    className,
    classNameIcon,
    classNameIconActive
}: CompareToggleProps) => {
    const dispatch = useDispatch();
    const localOfferIds = useSelector(selectLocalOfferIds);
    const { data: compareData } = useGetCompareOffersQuery(undefined, {
        skip: !isAuth
    });
    const [addOfferToComparison, { isLoading: isAdding }] = useAddOfferToComparisonMutation();
    const [removeOfferFromComparison, { isLoading: isRemoving }] =
        useRemoveOfferFromComparisonMutation();

    const [isComparison, setIsComparison] = useState(false);

    const canToggleComparison = !isAdding && !isRemoving && offerId;

    const toggleComparison = async (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        if (!canToggleComparison) return;

        const prevComparisonState = isComparison;
        setIsComparison(!isComparison);

        try {
            if (isAuth) {
                await (prevComparisonState
                    ? removeOfferFromComparison({ offerId }).unwrap()
                    : addOfferToComparison({ offerUuid: offerId }).unwrap());
                return;
            }

            if (!prevComparisonState && localOfferIds.length >= 10) {
                toast.error(COMPARE_LIMIT_ERROR, {
                    autoClose: 3000
                });
                setIsComparison(prevComparisonState);
                return;
            }

            dispatch(prevComparisonState ? removeLocalOffer(offerId) : addLocalOffer(offerId));
            setIsComparison(!prevComparisonState);
        } catch (error) {
            setIsComparison(prevComparisonState);
        }
    };

    useEffect(() => {
        if (!isAuth) {
            setIsComparison(localOfferIds.includes(offerId ?? ""));
            return;
        }

        if (compareData) {
            setIsComparison(compareData.offers.some((offer) => offer.id === offerId));
        }
    }, [isAuth, compareData, offerId, localOfferIds]);

    return (
        <div className={className} onClick={toggleComparison}>
            <CompareIcon
                className={classNames(classNameIcon || classes["compare-toggle"], {
                    [classNameIconActive || classes["compare-toggle__active"]]: isComparison
                })}
            />
        </div>
    );
};