import React, { useState } from "react";
import Link from "next/link";
import classNames from "classnames";
import { MainMenuButtonProps } from "./types";
import classes from "./MainMenuButton.module.scss";

export const MainMenuButton = ({ title, icon, link, isActive, disabled }: MainMenuButtonProps) => {
    const [isHovered, setIsHovered] = useState(false);
    const Icon = icon;

    return (
        <Link
            href={link}
            className={classNames({ [classes.Disabled]: disabled })}
            onClick={(e) => {
                if (disabled) {
                    e.preventDefault();
                }
            }}
        >
            <div
                onMouseEnter={() => {
                    setIsHovered(true);
                }}
                onMouseLeave={() => {
                    setIsHovered(false);
                }}
                className={classNames(classes.MainMenuButton, {
                    [classes.Active]: isActive
                })}
            >
                <div className={classes.ButtonContainer}>
                    <Icon color={isActive || isHovered ? "#3B85F7" : undefined} />
                    <span>{title}</span>
                </div>
            </div>
        </Link>
    );
};