import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Link from "next/link";
import { QueryStatus } from "@reduxjs/toolkit/dist/query/react";
import { CompareToggle } from "src/features/compare/CompareToggle";
import HoverGallery from "@components/Features/HoverGallery/HoverGallery";
import FavoriteIcon from "@components/Svg/FavoriteIcon";
import { numberWithSpaces } from "@src/utils/utils";
import { AppDispatch, AppState } from "@app/store/store";
import { OfferIcon } from "@features/catalog";
import { ProductPriceUsd } from "@features/product/product-price-usd";
import {
    addToFavorits,
    getFavoritesList,
    removeFromFavorits
} from "@entities/offers/api/favorites-slice";
import { getCurrencySymbol, toRelativeTime } from "@shared/lib/utils";
import { ViewsIcon } from "@shared/ui/Icon/ui/Common/ViewsIcon";
import { COUNTER_VIEWS_TITLE } from "./constants";
import { VerticalCatalogCardProps } from "./types";
import classes from "./vertical-catalog-card.module.scss";

export const SOLD_STATUS_ID = 5;

const VerticalCatalogCard = ({
    data: dataCard,
    disableShadows,
    style,
    buttonsBlockContent,
    disableFavoriteBlock,
    isMyAdsMode
}: VerticalCatalogCardProps) => {
    const [isArchive, setIsArchive] = useState(false);
    const isAuthorized = useSelector((state: AppState) => state.auth.accessToken);
    const hasToken = typeof isAuthorized === "string" && isAuthorized.length > 0;

    const dispatch: AppDispatch = useDispatch();
    const fetchFavoritesList = async () => {
        const resultAction = await dispatch(getFavoritesList());
        if (getFavoritesList.fulfilled.match(resultAction) && resultAction?.payload) {
            setIsFavorite(resultAction.payload.some((offer) => offer.id === dataCard.id));
        }
    };

    const [isFavorite, setIsFavorite] = useState(false);

    const handleFavoriteClick = async (e: React.MouseEvent) => {
        e.preventDefault();
        if (isFavorite) {
            
            const result = await dispatch(
                removeFromFavorits({
                    id: offer.id
                })
            );
            if (result.meta.requestStatus === QueryStatus.fulfilled) {
                setIsFavorite(false);
            }
        } else {
            setIsFavorite(!isFavorite);
            const result = await dispatch(
                addToFavorits({
                    id: offer.id
                })
            );
            if (result.meta.requestStatus !== QueryStatus.fulfilled) {
                setIsFavorite(false);
            }
        }
    };

    const offer = dataCard;
    const offerTitle = offer.mark && offer.model ? `${offer.mark} ${offer.model}` : offer.title;
    const offerAddress = [offer.city, offer.address].filter(Boolean).join(", ");
    const priceInCurrency = `${numberWithSpaces(offer.price.toString())} ${getCurrencySymbol(offer.sourceId)}`;
    const showViewCounter = offer.statusId === 3 || offer.statusId === 5;

    useEffect(() => {
        fetchFavoritesList();
        setIsArchive(dataCard.statusId === SOLD_STATUS_ID);
    }, [dataCard]);

    return (
        <div className={classes.Root}>
            {isArchive && <div className={classes.DisabledText}>Снято с продажи</div>}
            <div
                className={isArchive ? classes.Disabled : ""}
                onClick={(e) => {
                    if (isArchive) {
                        e.preventDefault();
                        e.stopPropagation();
                    }
                }}
            >
                <Link
                    target="_blank"
                    style={{
                        ...style
                    }}
                    className={classes.MainLink}
                    href={`/${offer.statusId === 1 ? "my-ads/edit" : "product"}/${offer.id}`}
                >
                    <div
                        className={`${classes.VerticalCatalogCard} ${
                            disableShadows ? classes.DisableShadows : undefined
                        }`}
                    >
                        {disableFavoriteBlock != true && (
                            <>
                                <div
                                    className={classes.FavoriteBlock}
                                    onClick={handleFavoriteClick}
                                >
                                    <FavoriteIcon isSelected={isFavorite} />
                                </div>
                                <CompareToggle
                                    className={classes.CompareOffer}
                                    offerId={offer.id}
                                    isAuth={hasToken}
                                />
                            </>
                        )}

                        <HoverGallery
                            offer={offer}
                            pics={offer.imagesList}
                            style={{
                                height: 215
                            }}
                        />

                        <div className={classes.VerticalCardContent}>
                            <div className={classes.VerticalCardNameContainer}>
                                <div className={classes.VerticalCardName}>{offerTitle}</div>
                            </div>

                            <div className={classes.Price}>
                                <span className={classes.Price__source}>{priceInCurrency}</span>
                                <ProductPriceUsd
                                    className={classes.Price__usd}
                                    priceUsd={offer.priceUsd}
                                    price={offer.price}
                                    sourceId={offer.sourceId}
                                />
                            </div>

                            <div className={classes.Tags}>
                                <div>
                                    <span>{offer.year}</span> г
                                </div>
                                <div>
                                    <span>{offer.mileage}</span> км
                                </div>
                            </div>

                            <ul className={classes.Props}>
                                <li>{offer.prepTransmission}</li>
                                <li>{offer.prepDriveType}</li>
                                <li>{offer.prepBodyType}</li>
                                <li>{offer.prepColor}</li>
                            </ul>

                            <div className={classes.Location}>
                                <svg
                                    width="8"
                                    height="8"
                                    viewBox="0 0 8 8"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <circle cx="4" cy="4" r="4" fill="#3B85F7" />
                                </svg>

                                <span>{offerAddress}</span>
                            </div>

                            {isMyAdsMode && showViewCounter && (
                                <div className={classes.MyAdsInfo} title={COUNTER_VIEWS_TITLE}>
                                    <ViewsIcon />
                                    <span className={classes.MyAdsInfo__views}>
                                        {offer.totalViews ?? 0}
                                    </span>
                                </div>
                            )}

                            {!buttonsBlockContent && (
                                <div className={classes.Footer}>
                                    <OfferIcon
                                        className={classes.Footer_icon}
                                        sourceId={offer.sourceId}
                                    />
                                    <div className={classes.UpdatedTime}>
                                        {toRelativeTime(offer.createdAt)}
                                    </div>
                                </div>
                            )}

                            <div className={classes.SecondFooter}>
                                {buttonsBlockContent && (
                                    <div className={classes.buttonsBlockContent}>
                                        {buttonsBlockContent}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    );
};
export default VerticalCatalogCard;